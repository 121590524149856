<template>
  <div class="cookie-overlay">
    <b-modal
      id="cookie-consent-modal"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      hide-header
      style="overflow-y: hidden !important"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered my-1"
        role="document"
      >
        <div class="modal-content text-gray-light">
          <div
            id="modal-header"
            class="modal-header border-bottom-0 py-1 py-sm-3"
          >
            <h2 class="modal-title mb-0 mx-auto">
              {{ $t("Nastavenie Cookies") }}
            </h2>
          </div>
          <div class="modal-body py-0">
            <div class="mx-2 mx-lg-4">
              <p class="mb-2 mb-sm-4">
                {{
                  $t(
                    "V Zmudri používame cookies a iné technológie za účelom poskytovania našich služieb, vylepšenia vášho užívateľského zážitku, analýzy používania našich stránok a pri cielení reklamy. Potrebujeme teda váš súhlas na:"
                  )
                }}
              </p>
              <p class="mb-2 mb-sm-4">
                {{
                  $t(
                    "Vaše osobné údaje budú spracované a informácie z vášho zariadenia (súbory cookie, jedinečné identifikátory a ďalšie údaje zariadenia) môžu byť uchovávané. Niektoré cookies sú nevyhnutné pre fungovanie, iné môžeme používať len s vaším súhlasom. Vaše preferencie môžete kedykoľvek zmeniť cez odkaz v dolnej časti stránky. Viac o našich zásadách ochrany súkromia."
                  )
                }}
              </p>
            </div>
          </div>
          <div class="modal-footer border-top-0 d-block py-1 py-sm-3">
            <div class="mx-2 mx-lg-4 buttons-wrapper">
              <div class="row">
                <div class="col-12 col-lg-12 px-5 px-lg-0">
                  <a
                    aria-label="cookies agree"
                    role="button"
                    tabindex="0"
                    class="btn btn-primary btn-block"
                    @click="sendCookie('modal')"
                    >{{ $t("Súhlasím") }}</a
                  >
                  <a
                    aria-label="cookies manage"
                    role="button"
                    tabindex="0"
                    class="text"
                    @click="openMoreOptions()"
                    >{{ $t("Spravovať možnosti") }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-sidebar
      id="cookie-consent-sidebar"
      no-close-on-esc
      no-close-on-backdrop
      backdrop-variant="dark"
      backdrop
      shadow
      back-drop
      no-header
      right
    >
      <div class="sidebar__content" style="padding: 0 20px">
        <img
          style="width: 16px; height: 16px; margin: 8px 0 10px 0"
          src="thinX.png"
          @click="closeMoreOption()"
        />

        <h2 class="mb-5">{{ $t("Správa Cookies") }}</h2>

        <form id="cookie-consent-form">
          <div class="form-group pb-2 border-bottom">
            <input
              type="checkbox"
              v-model="cookies.cookies_esential"
              id="cookies_esential"
              checked
              disabled
            />
            <label for="cookies_esential"
              >{{ $t("Základné Cookies")
              }}<sup class="tooltip-icon"
                ><i
                  class="icon-info-circle custom-tooltip"
                  data-target="#tooltip-cookies-esentials"
                  data-container=".sidebar"
                ></i></sup
            ></label>
            <div class="text-gray-light">
              <small>{{
                $t(
                  "Zakladné cookies zabezpečujú základné funkcie webovej stránky, bez nich nemusí webová stránka fungovať správne."
                )
              }}</small>
            </div>
          </div>
          <div id="tooltip-cookies-esentials" class="d-none">
            <div class="border-top-0 border-bottom-0 border-right-0">
              <div class="row align-items-center">
                <div class="col-sm text-grey-light">
                  <p class="m-0 text-gray-light">
                    {{
                      $t(
                        "Tieto cookies umožňujú základné funkcie webovej stránky ako napríklad prihlásenie na Váš účet alebo uloženie Vašich nastavení. Tieto cookies môžete vypnúť zmenou nastavenia Vášho internetového prehliadača, avšak webová stránka nemusí bez nich fungovať správne."
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group pb-2 border-bottom">
            <input
              type="checkbox"
              name="analytic'"
              v-model="cookies.cookie_analytic"
              id="cookie_analytic"
              data-exponea-category="CookiesStatistical"
            />
            <label for="cookies_analytic">{{ $t("Analytické Cookies") }}</label>
            <div class="text-gray-light">
              <small>{{
                $t(
                  "Analytické cookies pomáhajú zlepšiť webovú stránku zberom a zaznamenávaním údajov o Vašom užívaní našej webovej stránky."
                )
              }}</small>
            </div>
          </div>

          <div class="form-group pb-2 border-bottom">
            <input
              type="checkbox"
              name="preferential"
              v-model="cookies.cookies_preferential"
              id="cookies_preferential"
              data-exponea-category="CookiesPreferences"
            />
            <label for="cookies_preferential">{{
              $t("Preferenčné Cookies")
            }}</label>
            <div class="text-gray-light">
              <small>{{
                $t(
                  "Preferenčné cookies používame na prispôsobovanie obsahu našej webovej stránky."
                )
              }}</small>
            </div>
          </div>

          <div class="form-group pb-2 border-bottom">
            <input
              type="checkbox"
              name="marketing"
              v-model="cookies.cookies_marketing"
              id="cookies_marketing"
              data-exponea-category="CookiesMarketing"
            />
            <label for="cookies_marketing">{{ $t("Reklamné Cookies") }}</label>
            <div class="text-gray-light">
              <small>{{
                $t(
                  "Reklamné cookies využívame na zobrazovanie cielenej reklamy."
                )
              }}</small>
            </div>
          </div>

          <p>
            {{
              $t(
                "Svoje voľby môžete neskôr zmeniť v časti Správa Cookies v dolnej časti našej webovej stránky."
              )
            }}
          </p>

          <a
            href="https://docs.google.com/document/d/1RfRFlz4UAZgXEGnpqoMqugRU_gCp12jG/edit?usp=sharing&ouid=101522684688141446307&rtpof=true&sd=true"
            target="_blank"
            >{{
              $t(
                "Pre viac informácií o používaní Cookies prosím navštívte Zásady ochrany osobných údajov."
              )
            }}</a
          >

          <div :reopen="checked()" class="form__group" style="margin-top: 10px">
            <button
              class="btn btn-primary btn-block"
              type="button"
              @click.prevent="sendCookie('sidebar')"
            >
              {{ $t("Uložiť") }}
            </button>
          </div>
        </form>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import analytics from "/utils/analytics";
import router from "/router";
import getCookie from "/utils/getCookie";

export default {
  components: {
    // 'svg-user-icon': 				() => import('/assets/icons/user.svg?inline'),
    // 'svg-light-bulb-icon': 	() => import('/assets/icons/lightBulb.svg?inline'),
  },
  data() {
    return {
      cookies: {
        cookies_esential: true,
        cookie_analytic: true,
        cookies_preferential: true,
        cookies_marketing: true,
      },
      name: "cookieconsent2",
      days: 360,
      noClose: false,
      checker: false,
    };
  },
  async mounted() {
    const cookies = getCookie.getCookie();
    if (!cookies) {
      this.$bvModal.show("cookie-consent-modal");
      document.querySelector("html").style.overflowY = "hidden";
      return;
    }

    this.allowCookies();
  },
  methods: {
    async openMoreOptions() {
      await this.$bvModal.hide("cookie-consent-modal");
      this.$root.$emit("bv::toggle::collapse", "cookie-consent-sidebar");
      this.noClose = true;
    },
    async closeMoreOption() {
      await this.$root.$emit("bv::toggle::collapse", "cookie-consent-sidebar");
      if (this.noClose) this.$bvModal.show("cookie-consent-modal");
    },
    sendCookie(type) {
      if (type === "modal") this.$bvModal.hide("cookie-consent-modal");
      else if (type === "sidebar")
        this.$root.$emit("bv::toggle::collapse", "cookie-consent-sidebar");
      document.querySelector("html").style.overflowY = "auto";
      let expires;
      let value;
      if (this.cookies instanceof Object) {
        value = JSON.stringify(this.cookies);
      }

      if (this.days) {
        let date = new Date();
        date.setTime(date.getTime() + this.days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = this.name + "=" + value + expires + "; path=/";
      this.allowCookies();
      this.noClose = false;
      this.checker = false;
    },
    allowCookies() {
      const cookies = getCookie.getCookie();

      if (!cookies) return;

      if (cookies.cookie_analytic) {
        analytics.addGoogleAnalytics(router);
        analytics.addGoogleTagManager(router);
      }

      if (cookies.cookies_marketing) {
        analytics.addFacebookPixel(router);
      }
    },
    checked() {
      const cookies = getCookie.getCookie();
      if (cookies !== null && this.checker === false) this.cookies = cookies;
      if (this.checker === false) this.checker = !this.checker;
    },
  },
};
</script>
<style lang="sass" scoped>
.cookie-overlay
	z-index: 99999 !important

b-modal
	width: 40% !important
	overflow-y: hidden !important

.modal-dialog
	overflow-y: hidden !important

.modal-conten
	overflow-y: hidden !important

.text
	display: flex
	margin: 10px 40px 0
	text-decoration: underline
	font-size: 12px
	color: gray
	justify-content: center



@media only screen and (max-height: 880px)
	.btn
		height: 30px
		max-width: 300px
		padding: 3px 0px 0px 0px
		margin-bottom: 10px
	.buttons-wrapper
		display: flex
		justify-content: center
		align-items: center
</style>
