import * as Sentry from "@sentry/vue";

export default {
  getCookie(): Record<string, unknown> | null {
    const nameEQ = "cookieconsent2" + "=";
    const ca = document.cookie.split(";");

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) === 0) {
        const value = c.substring(nameEQ.length, c.length);

        try {
          return JSON.parse(value);
        } catch (error) {
          Sentry.captureException(error);
          console.warn(error);
          return null;
        }
      }
    }

    return null;
  },
};
